import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MenuIconDarkGreen from '../assets/images/menu-icon-dark-green.png';
import LogoTextDarkGreen from '../assets/images/logo-text-dark-green.png';
import MenuIconPaleGreen from '../assets/images/menu-icon-pale-green.png';
import LogoTextPaleGreen from '../assets/images/logo-text-pale-green.png'; 
import CloseIconGreen from '../assets/images/close-green.png';

import DesktopNav from '../nav/DesktopNav';
import { Drawer } from 'antd';
import MobileNav from '../nav/MobileNav';
import StoreNav from '../nav/StoreNav';

const MobileMenuCloseIcon = () => {
  return (
    <span className="mobileMenuCloseIcon">
      <img src={CloseIconGreen} alt="Close" />
    </span>
  );
}

const HeaderBar = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [storeNav, setStoreNav] = useState({
    display: false,
    visible: false,
  });
  const [headerBarSize, setHeaderBarSize] = useState(window.innerWidth >= 900 ? 'large' : 'small'); // small | large

  const history = useHistory();

  const { theme, hideNav, storeNavButton, actionButton, handleActionButton, actionButtonText, storeNavButtonText } = props;

  const storeNavRef = useRef(null);
  const storeNavButtonRef = useRef(null);

  const openStoreNav = () => {
    // Display nav, then transition opacity
    setStoreNav((prevState) => ({
      ...prevState,
      display: true,
    }));
    setTimeout(() => {
      setStoreNav((prevState) => ({
        ...prevState,
        visible: true,
      }));
    }, 10);
  };

  const closeStoreNav = () => {
    // Transition opacity, then set display to none
    setStoreNav((prevState) => ({
      ...prevState,
      visible: false,
    }));
    setTimeout(() => {
      setStoreNav((prevState) => ({
        ...prevState,
        display: false,
      }));
    }, 500);
  };

  useEffect(() => {
    // Add handlers upon mount
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setStoreNav({
          display: false,
          visible: false,
        });
      }
      if (window.innerWidth >= 900) {
        setHeaderBarSize('large');
      } else {
        setHeaderBarSize('small');
      }
      if (window.innerWidth >= 1234) {
        setShowMenu(false);
      }
    };

    const handleClick = (e) => {
      if (storeNavButtonRef.current && storeNavButtonRef.current.contains(e.target)) {
        // If the store nav button is clicked, do nothing
        return;
      }

      if (
        storeNavRef.current &&
        !(storeNavRef.current.contains(e.target))
      ) {
        // If anything outside of the store navigation is clicked, close the store nav
        closeStoreNav();
      }
    };

    window.addEventListener('resize', handleResize, true);
    window.addEventListener("mousedown", handleClick);

    // Remove listeners upon unmount
    return (() => {
      window.removeEventListener('resize', handleResize, true);
      window.removeEventListener("mousedown", handleClick);
    });
  }, []);

  // Determine images
  let menuIcon, logoImage;
  if (theme === 'light') {
    menuIcon = MenuIconPaleGreen;
    logoImage = LogoTextPaleGreen;
  } else {
    menuIcon = MenuIconDarkGreen;
    logoImage = LogoTextDarkGreen;
  }

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleMenuButton = () => {
    setShowMenu(true);
  };

  const handleLogoButton = () => {
    history.push('/');
  };

  const handleStoreNavButton = () => {
    if (storeNav.display && storeNav.visible) {
      closeStoreNav();
    } else if (!storeNav.display && !storeNav.visible) {
      openStoreNav();
    }
  };

  const barClassName = `HeaderBar${theme === 'light' ? ' light' : ''}`;

  const mobileMenuHeaderStyles = {
    paddingLeft: headerBarSize === 'small' ? 20 : 30,
    paddingTop: headerBarSize === 'small' ? 22 : 42,
    paddingBottom: headerBarSize === 'small' ? 22 : 42,
    backgroundColor: '#FDFAF6',
    borderBottom: 'none',
  };

  const mobileMenuContainerStyles = {
    padding: 0,
    backgroundColor: '#FDFAF6',
  };

  const storeNavButtonLabel = storeNavButtonText ?? 'Shop By Location';

  return (
    <header className={barClassName}>
      <Drawer 
        placement="left"
        onClose={handleMenuClose}
        open={showMenu}
        headerStyle={mobileMenuHeaderStyles}
        bodyStyle={mobileMenuContainerStyles}
        closeIcon={<MobileMenuCloseIcon />}
        width={360}
      >
        <MobileNav onButtonPress={handleMenuClose} />
      </Drawer>
      <div className="contentContainer">
        <div className="content">
          <span className="leftOption">
            {/* Will display full navigation on desktop */}
            {!hideNav && (
              <>
                <button type="button" className="menuButton" onClick={handleMenuButton}>
                  <img src={menuIcon} alt="Menu icon" />
                </button>
                <DesktopNav theme={theme} />
              </>
            )}
          </span>
          <span className="logo">
            <button type="button" className="logoButton" onClick={handleLogoButton}>
              <img src={logoImage} alt="Sweetspot" />
            </button>
          </span>
          {/* Will be a button on desktop; empty on mobile */}
          <span className="rightOption">
            {storeNavButton && (
            <button type="button" ref={storeNavButtonRef} className="actionButton" onClick={handleStoreNavButton}>
              <span>{storeNavButtonLabel}</span>
            </button>
            )}
            {actionButton && (
              <button type="button" className="actionButton" onClick={handleActionButton}>
                <span>{actionButtonText}</span>
              </button>
            )}
            <StoreNav 
              display={storeNav.display}
              visible={storeNav.visible}
              navRef={storeNavRef}
              close={closeStoreNav} 
            />
          </span>
        </div>
      </div>
    </header>
  );
};

export default HeaderBar;
