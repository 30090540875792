import React from 'react';

// Import components
import Delivery from './delivery/Delivery';

// Import React Router
// https://reactrouter.com/
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

const RhodeIsland = () => {
  // Acquire route match data
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/delivery`}>
        <Delivery />
      </Route>
      <Route exact path="/">
        <Redirect to={`${match.url}/delivery`} />
      </Route>
      <Route path="*">
        <Redirect to={`${match.url}/delivery`} />
      </Route>
    </Switch>
  )
};

export default RhodeIsland;