import React from 'react';
import { useHistory } from 'react-router-dom';

import HeaderBar from '../../../shared/HeaderBar';
import AnimatedButton from '../../../shared/AnimatedButton';
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function FranchiseBanner() {
  const history = useHistory();
  const analytics = getAnalytics();
  const handleInfoButton = () => {
    history.push('/franchising/info-request');
    logEvent(analytics, "franchise_info_pressed");
  };

  return (
    <>
      <div className="BannerBackground" />
      <div className="Banner">
        <HeaderBar 
          theme="light" 
          hideNav
          actionButton
          handleActionButton={handleInfoButton}
          actionButtonText="Request Information"
        />
        <div className="contentWrapper">
          <div className="content">
            <h1>
              Welcome <span className="desktopOnly">to</span> Sweetspot Franchising
            </h1>
            <div className="infoButtonContainer">
              <AnimatedButton
                label="Request Information"
                handler={handleInfoButton}
                className="franchiseBanner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
