import React from 'react';

import PlantVideo from '../../../assets/videos/plant-video-v2.mp4';
import PlantVideoPoster from '../../../assets/images/plant-video-v2-image.jpg';

export default function Sustainability() {
  return (
    <section className="Sustainability">
      <div className="contentWrapper">
        <div className="content">
          <div className="videoContainerPadding">
            <div className="videoContainer">
              <video
                playsInline
                autoPlay
                muted
                loop
                poster={PlantVideoPoster}
                className="video"
              >
                <source src={PlantVideo} type="video/mp4" />
                <img src={PlantVideoPoster} className="videoImage" alt="Cannabis plants growing in natural light" />
              </video>
            </div>
          </div>
          <div className="itemsWrapper">
            <div className="item">
              <p className="title">
                Pesticide-Free Flowers
              </p>
              <p className="description">
                Sweetspot grown flower is proudly chemical free.
              </p>
            </div>
            <div className="item">
              <p className="title">
                Water-Conscious
              </p>
              <p className="description">
                100% of the water used in our cultivation facilities is recycled back into the system.
              </p>
            </div>
            <div className="item">
              <p className="title">
                Recyclable
              </p>
              <p className="description">
                All Sweetspot products are sold in fully recyclable packaging.
              </p>
            </div>
            <div className="item fullBottomBorder">
              <p className="title">
                Carbon Neutral
              </p>
              <p className="description">
                From hydro-electric to solar, we are pursuing a carbon neutral facility by 2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
