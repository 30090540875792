import React from 'react';

const Perks = (props) => {
  const { scrollRef } = props;

  return (
    <section className="Perks">
      <div className="contentContainer" ref={scrollRef}>
        <div className="content">
          <p className="sectionTitle">
            Submit your registration info and receive special perks!
          </p>
          <div className="featureContainer">
            <div className="feature">
              <div className="image truck">
                <img 
                  src={require('../../../../assets/images/delivery-truck.png')} 
                  alt="Delivery truck icon emblazoned with FREE written on the side"
                />
              </div>
              <div className="text">
                Delivery fees are waived for medical patients
              </div>
            </div>
            <div className="feature">
              <div className="image percentile">
                <img 
                  src={require('../../../../assets/images/percentile-shape.png')} 
                  alt="Percentile sign inside ridged circle on top of green shape"
                />
              </div>
              <div className="text">
                20% off your first order
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Perks;