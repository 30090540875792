import React from 'react';

// Import images
import RelaxingIcon from '../../../assets/images/relaxing.png';
import EnergizingIcon from '../../../assets/images/energizing.png';
import LowDoseIcon from '../../../assets/images/low-dose.png';
import HighDoseIcon from '../../../assets/images/high-dose.png';
import SmokingIcon from '../../../assets/images/smoking.png';
import EdiblesIcon from '../../../assets/images/edibles.png';

// Import content
import { quizResultsMessage1, quizResultsMessage2 } from '../../../helpers/quiz';

export default function Results(props) {
  const { quizResults } = props;

  const getResultIcon = (resultId) => {
    let image;
    let altText;
    switch (resultId) {
      case "relaxing":
        image = RelaxingIcon;
        altText = "A circle with the lower half filled with white and the upper half empty";
        break;
      case "energizing":
        image = EnergizingIcon;
        altText = "A circle with the upper half filled with white and the lower half empty";
        break;
      case "lowDose":
        image = LowDoseIcon;
        altText = "A series of five circles in a star formation, three of which are solid white and two of which are outlined";
        break;
      case "highDose":
        image = HighDoseIcon;
        altText = "A series of five white circles in a star formation";
        break;
      case "smoking":
        image = SmokingIcon;
        altText = "A circle shape with low ridged points around the perimeter";
        break;
      case "edibles":
        image = EdiblesIcon;
        altText = "A circle shape with star-like ridged points around the perimeter";
        break;
      default:
        image = "";
        altText = "";
    }
    return { image, altText };
  }

  // Build UI
  const resultsDetails = quizResults.map((resultData) => {
    const { id, title, text } = resultData;
    const resultIcon = getResultIcon(id);

    return (
      <div className="resultContent" key={id}>
        <div className="resultTitle">
          <span className="icon">
            <img src={resultIcon.image} alt={resultIcon.altText} />
          </span>
          <span className="text">
            {title}
          </span>
        </div>
        <p className="resultText">
          {text}
        </p>
      </div>
    );
  });

  return (
    <section className="Results">
      <div className="resultsMessageWrapper">
        <p className="resultsMessage first">
          {quizResultsMessage1}
        </p>
        <p className="resultsMessage second">
          {quizResultsMessage2}
        </p>
      </div>
      <div className="resultsDetailsWrapper">
        {resultsDetails}
      </div>
    </section>
  );
}
