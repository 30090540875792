import React, { useState } from 'react';

const Residency = (props) => {
  const { handleContinue } = props;

  const [noResidency, setNoResidency] = useState(false);

  return noResidency ? (
    <>
      <h1>
        Sorry!  You must be a Rhode Island medical card holder to order.
      </h1>
      <p>
        <a href="https://health.ri.gov/healthcare/medicalmarijuana/for/patients/">Click here</a> for information on how to get a RI medical card.
      </p>
      <div className="screeningButtons">
        <button 
          className="screeningButtonNo"
          type="button"
          onClick={() => setNoResidency(false)}
        >
          Back
        </button>
      </div>
    </>
  ) : (
    <>
      <h1>
        Are you a resident of Rhode Island?
      </h1>
      <div className="screeningButtons">
        <button 
          className="screeningButtonNo"
          type="button"
          onClick={() => setNoResidency(true)}
        >
          No
        </button>
        <button 
          className="screeningButtonYes"
          type="button"
          onClick={() => handleContinue(true)}
        >
          Yes
        </button>
      </div>
    </>
  );
};

export default Residency;