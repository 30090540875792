import Footer from '../../shared/Footer';
import HeaderBar from '../../shared/HeaderBar';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function PageNotFound() {
  const history = useHistory();

  const returnHome = () => {
    history.push('/');
  };

  return (
    <div className="FranchiseInfoRequest">
      <HeaderBar theme="light" hideNav />
      <br></br>
      <center>
        <h1> PAGE NOT FOUND </h1>
        <div className="gateButtons">
          <button
            type="button"
            className="gateButtonNo"
            onClick={returnHome}
          >
            Click here to return home
          </button>
        </div>
      </center>
      <Footer />
    </div>
  );
}