import React from 'react';
import { useHistory } from "react-router-dom";

const DeliveryMethod = (props) => {

  const history = useHistory();
    
  return (
    <>
      <h1 className="thinner3">
        Please choose your delivery method.
      </h1>
      <div className="screeningButtons">
        <button 
          className="screeningButtonNo wide"
          type="button"
          onClick={() => history.push(`/store/ri001/menu/rec`)}
        >
          Adult-Use
        </button>
        <button 
          className="screeningButtonYes wide"
          type="button"
          onClick={() => history.push(`/store/ri001/menu/med`)}
        >
          Medical
        </button>
      </div>
    </>
  );
};

export default DeliveryMethod;