import React from 'react';

// Import components
import LandingBanner from './sections/Banner';
import Blurb from '../../shared/Blurb';
import GetStarted from './sections/GetStarted';
import Sustainability from './sections/Sustainability';
import Locations from './sections/Locations';
import Products from './sections/Products';
import SubscribeForm from '../../shared/SubscribeForm';
import Footer from '../../shared/Footer';
import SectionHeading from '../../shared/SectionHeading';
import { Helmet } from 'react-helmet';

export default function Landing() : any {
  return (
    <main className="Landing">
        <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sweetspot Weed Dispensary: Home</title>
        <link rel="canonical" href="http://www.sweetspotfarms.com/" />
      </Helmet>
        </div>
      <LandingBanner />
      <Blurb
        text="Take control of your cannabis experience."
      />
      <GetStarted />
      <Blurb
        text="We positively impact the communities we serve from seed to sale."
      />
      <SectionHeading theme="light" text="Sustainability" scrollRef="sustainability" />
      <Sustainability />
      <SectionHeading theme="light" text="Our Stores" scrollRef="locations" />
      <Locations />
      <SectionHeading theme="light" text="Our Products" scrollRef="products" />
      <Products />
      <SubscribeForm />
      <Footer />
    </main>
  );
}
