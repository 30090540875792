import React from 'react';

// Import helpers
import { rhodeIslandFAQ } from '../../../../helpers/data';

const FAQ = () => {
  return (
    <section className="FAQ">
      <div className="contentContainer">
        <div className="content">
          <p className="sectionTitle">
            FAQ
          </p>
          <div className="faqWrapper">
            { rhodeIslandFAQ.map((faqItem, index) => {
              const { question, answer } = faqItem;
              const answerContent = 
                (question === 'How does payment work?') ?
                (
                  <p className="answer">
                    At this time, we can only accept payment via <a href="https://app.canpaydebit.com/login">CanPay</a>. Payment will be taken in person at the time of delivery.
                  </p>
                ) : (
                  <p className="answer">
                    { answer }
                  </p>
                );
              return (
                <div className="faqItem" key={index}>
                  <p className="question">
                    { question }
                  </p>
                  {answerContent}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;