import React, { useRef, useState } from 'react';

// Import React Google reCAPTCHA
// https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha';

// Import custom spinner
import LogoSpinner from '../../../shared/LogoSpinner';

// Import helpers and services
import { ageRangeValues, devMode, genderValues, stateValues } from '../../../helpers/data';
import { testEmail } from '../../../helpers/validation';
import { Checkbox } from 'antd';
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

const Complete = ({
  userData,
  updateQuestionIndex,
  updateUserData,
  completeQuiz,
  transition,
  processing,
}) => {
  const [formConsent, setFormConsent] = useState(false);
  const reCaptchaRef = useRef(null);
  const analytics = getAnalytics(); 

  // Build handlers for reCAPTCHA widget
  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  }

  // Build handlers
  const handleBackButton = () => {
    // Update question index
    updateQuestionIndex("decrement");
    logEvent(analytics, "quiz_back_pressed")
  }

  const handleGetResultsButton = async () => {
    if (processing) {
      console.log('Please wait...');
      return;
    }

    // Check to see if form fields have been filled out
    // If not, show alert; otherwise allow visitor to proceed
    if (!userData.firstName.trim().length || !userData.lastName.trim()) {
      window.alert("Please enter your first and last name.");
    } else if (!testEmail(userData.email.trim())) {
      window.alert("Please enter a valid email.");
    } else if (!userData.location) {
      window.alert("Please select a location.");
    } else if (!userData.age) {
      window.alert("Please select an age range value.");
    } else if (!formConsent) {
      window.alert("You must consent to be contacted by Sweetspot in order to receive your results.");
    } else {
      // Attempt to acquire reCAPTCHA token
      const token = await reCaptchaRef.current?.executeAsync();
      if (!token) {
        // Error handling handled by callbacks
        return;
      }
      completeQuiz(token);

      logEvent(analytics, 'quiz_complete')
      setUserProperties(analytics, { 
        customer_email: userData.email,
        customer_location: userData.location,
        customer_age: userData.age,
        customer_gender: userData?.gender || 'None Selected',
        customer_contact_opt_in: formConsent
       })
    }
  }

  // Build form select options
  let locationOptions = [];
  let ageOptions = [];
  let genderOptions = [];

  locationOptions.push(  
    <option key={-1} value="">
      {'Location (required)'}
    </option>
  );

  stateValues.forEach((state) => {
    locationOptions.push(
      <option 
        key={state.abbr}
        value={state.name}
      >
        {state.name}
      </option>
    );
  });

  ageOptions.push(  
    <option key={-1} value="">
      {'Age (required)'}
    </option>
  );

  ageRangeValues.forEach((age, index) => {
    ageOptions.push(
      <option 
        key={index}
        value={age}
      >
        {age}
      </option>
    );
  });

  genderOptions.push(  
    <option key={-1} value="">
      {'Gender (optional)'}
    </option>
  );

  genderValues.forEach((gender, index) => {
    genderOptions.push(
      <option 
        key={index}
        value={gender}
      >
        {gender}
      </option>
    );
  });

  // Build React reCaptcha element
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  const questionIndexStyle = `questionIndex${transition ? ' invisible' : ''}`;
  const quizContentStyle = `quizContent${transition ? ' invisible' : ''}`;

  return (
    <section className="Complete">
      <div>
        <div className="quizHeading">
          <span className="navControls">
            <button
              className="backButton"
              onClick={handleBackButton}
            >
              &lt; Back
            </button>
          </span>
          <span className={questionIndexStyle}>
            Thank you!
          </span>
          <span className="navControls" />
        </div>
        <div className={quizContentStyle}>
          <p className="quizCompletion">
            Please provide your information to see your results!
          </p>
          <div className="formWrapper">
            <div className="formRowWrapper">
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <input 
                    type="text"
                    name="firstName"
                    placeholder="First Name (required)"
                    value={userData.firstName}
                    onChange={(event) => {
                      updateUserData("firstName", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <input 
                    type="text"
                    name="lastName"
                    placeholder="Last Name (required)"
                    value={userData.lastName}
                    onChange={(event) => {
                      updateUserData("lastName", event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="formRowWrapper">
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <input 
                    type="email"
                    name="email"
                    placeholder="Email (required)"
                    value={userData.email}
                    onChange={(event) => {
                      updateUserData("email", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <select 
                    name="location"
                    value={userData.location}
                    onChange={(event) => {
                      updateUserData("location", event.target.value);
                    }}
                  >
                    { locationOptions }
                  </select>
                </div>
              </div>
            </div>
            <div className="formRowWrapper grid">
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <select 
                    name="age"
                    value={userData.age}
                    onChange={(event) => {
                      updateUserData("age", event.target.value);
                    }}
                  >
                    { ageOptions }
                  </select>
                </div>
              </div>
              <div className="formRowItem">
                <div className="fullWidthFormItemWrapper">
                  <select 
                    name="gender"
                    value={userData.gender}
                    onChange={(event) => {
                      updateUserData("gender", event.target.value);
                    }}
                  >
                    { genderOptions }
                  </select>
                </div>
              </div>
            </div>
            <div className="formConsent">
              {/* <span className="checkboxContainer"> */}
              <Checkbox
                checked={formConsent}
                onChange={(event) => {
                  setFormConsent(event.target.checked);
                }}
              >
                <span className="label">
                  Sweetspot may contact me with my quiz results, exclusive offers, and other information. I will be able to unsubscribe from the email list at any time by selecting "unsubscribe" at the bottom of any email.
                </span>
              </Checkbox>
            </div>
            <div className="submitButtonWrapper">
              <button type="button" className="submitButton" onClick={handleGetResultsButton}>
                { processing &&
                  <span className="logoSpinnerContainer">
                    <LogoSpinner />
                  </span>
                }
                Get your results!
              </button>
            </div>
            {reCaptcha}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Complete;
