import React from 'react';

import Age from './Age';

// Import Ant Design
// https://ant.design/
import { Modal } from 'antd';

export default function Gate(props : any) {
  const {
    visible,
    handleAgeGateYes
  } = props;

  // Build modal renderer
  const modalRender = () => (
    <div className="gateWrapper">
      <div className="gateContainer">
        <header>
          <span className="image">
            <img 
              src={require('../../assets/images/logo-text-dark-green.png')} 
              alt="Sweetspot"
            />
          </span>
        </header>
        <Age
          handleContinue={handleAgeGateYes}
        />
      </div>
    </div>
  );

  return ( 
    <Modal
      modalRender={modalRender}
      open={visible}
      maskClosable={false}
      centered
      width={'90%'}
      style={{ paddingBottom: 0, height: 674}}
    />
  );
}
