import React, {useRef, useState} from 'react';

// Import React Router
// https://reactrouter.com/
import {
  useHistory,
} from "react-router-dom";

// Import React Google reCAPTCHA
// https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha';

// Import middleware
import {sendFranchiseInfoRequestForm} from '../../../services/forms';

// Import header images
import LogoWhite from '../../../assets/images/logo-text-pale-green.png';
import CloseWhite from '../../../assets/images/close-white.png';

//Import helpers and components
import {stateValues, devMode} from '../../../helpers/data';
import AnimatedButton from '../../../shared/AnimatedButton';
import {testEmail, testPhone} from '../../../helpers/validation';
import {parsePhone} from '../../../helpers/functions';
import { Helmet } from 'react-helmet';

const stateOptions = [(
  <option
    key="default"
    value=""
  >
    Which State Do You Reside In?
  </option>
)]

stateValues.forEach((state) => {
  stateOptions.push(
    <option 
      key={state.abbr}
      value={state.name}
    >
      {state.name}
    </option>
  );
});

const defaultFranchiseInfoRequestFormData = {
  firstName: '',
  lastName: '',
  email: '',
  streetAddress: 'N/A',
  city: 'N/A',
  state: '',
  netWorth: '',
  availableCash: '',
  desiredLocation: '',
  phone: {
    number: '',
    formatted: ''
  },
  availability: '',
  referral: '',
};

export default function InfoRequest() {
  const [formData, setFormData] = useState(defaultFranchiseInfoRequestFormData);
  const [processing, setProcessing] = useState(false);

  const reCaptchaRef = useRef(null);

  const history = useHistory();

  // Build handlers for reCAPTCHA widget
  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  }

  // Build handlers
  const handleClose = () => {
    history.push("/franchising");
  }

  // Build callback for updating user data
  // Takes a key and value (strings) as arguments
  const updateFormData = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  const handlePhoneInput = (value) => {
    const phone = parsePhone(value);
    updateFormData('phone', phone);
  };

  // Build callback for completing quiz
  const handleSubmit = async (event) => {
    event.preventDefault();

    const firstName = formData.firstName.trim();
    const lastName = formData.lastName.trim();
    const email = formData.email.trim();
    const streetAddress = formData.streetAddress.trim();
    const city = formData.city.trim();
    const {state} = formData;
    const netWorth = formData.netWorth.trim();
    const availableCash = formData.availableCash.trim();
    const desiredLocation = formData.desiredLocation.trim();
    const phone = formData.phone.number;
    const availability = formData.availability.trim();
    const referral = formData.referral.trim();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !state ||
      !availableCash ||
      !desiredLocation ||
      !phone ||
      !availability ||
      !referral
    ) {
      window.alert('Please complete all form fields.');
      return;
    }

    if (!testEmail(email)) {
      window.alert('Please enter a valid email.');
      return;
    }

    if (!testPhone(phone)) {
      window.alert('Please enter a valid phone number.');
      return;
    }

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }
    
    setProcessing(true);

    try {
      // Build callbacks
      const success = () => {
        setProcessing(false);
        window.alert('Form submission successful.');
        history.push('/franchising');
      }
      
      const failure = () => {
        setProcessing(false);
        window.alert('An error occurred.  Please try again later.');
      }

      // Build object to send to cloud functions
      const formSubmissionParams = {
        token,
        firstName,
        lastName,
        email,
        streetAddress,
        city,
        state,
        netWorth,
        availableCash,
        desiredLocation,
        phone,
        availability,
        referral,
        success,
        failure,
      };

      // Send to service
      sendFranchiseInfoRequestForm(formSubmissionParams);
    } catch (error) {
      console.log('Failed to submit info request form: ', error);
      setProcessing(false);
      window.alert('An error occurred.  Please try again later.');
    }
  }

  // Build React reCaptcha element
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <div className="FranchiseInfoRequest">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Sweetspot Weed Dispensary: Request Franchising Info</title>
          <link rel="canonical" href="https://sweetspotfarms.com/franchising/info-request"/>
     </Helmet>
      <header className="FranchiseInfoRequestHeader">
        <div className="contentContainer">
          <div className="content">
            <span className="leftOption" />
            <span className="logo">
              <span className="logoButton">
                <img 
                  src={LogoWhite} 
                  alt="Logo"
                />
              </span>
            </span>
            <span className="rightOption">
              <button 
                className="closeButton"
                onClick={handleClose}
              >
                <img 
                  src={CloseWhite} 
                  alt="Close"
                />
              </button>
            </span>
          </div>
        </div>
      </header>
      <main className="FranchiseInfoRequestForm">
        <div className="contentContainer">
          <div className="content">
            <div className="heading">
              <p>
                Please complete this form and we will get in touch to discuss our franchise offering in more detail.
              </p>
            </div>
            <form className="formContainer" onSubmit={handleSubmit}>
              <div className="formItems">
                <div className="formItem firstName">
                  <input 
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={(event) => {
                      updateFormData("firstName", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem lastName">
                  <input 
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={(event) => {
                      updateFormData("lastName", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem email">
                  <input 
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(event) => {
                      updateFormData("email", event.target.value);
                    }}
                  />
                </div>

                <div className="formItem netWorth">
                  <input 
                    type="text"
                    name="netWorth"
                    placeholder="Current Estimated Net Worth (optional)"
                    value={formData.netWorth}
                    onChange={(event) => {
                      updateFormData("netWorth", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem availableCash">
                  <input 
                    type="text"
                    name="availableCash"
                    placeholder="Cash Available for Investment (required)"
                    value={formData.availableCash}
                    onChange={(event) => {
                      updateFormData("availableCash", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem desiredLocation">
                  <input 
                    type="text"
                    name="desiredLocation"
                    placeholder="State/City of Interest"
                    value={formData.desiredLocation}
                    onChange={(event) => {
                      updateFormData("desiredLocation", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem phone">
                  <input 
                    type="tel"
                    name="phone"
                    placeholder="Best Phone # to Reach You"
                    value={formData.phone.formatted}
                    onChange={(event) => {
                      handlePhoneInput(event.target.value);
                    }}
                  />
                </div>
                <div className="formItem availability">
                  <input 
                    type="text"
                    name="availability"
                    placeholder="Best Time to Call"
                    value={formData.availability}
                    onChange={(event) => {
                      updateFormData("availability", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem referral">
                  <input 
                    type="text"
                    name="referral"
                    placeholder="How Did You Hear About Us?"
                    value={formData.referral}
                    onChange={(event) => {
                      updateFormData("referral", event.target.value);
                    }}
                  />
                </div>
                <div className="formItem state">
                  <select 
                    name="state"
                    value={formData.state}
                    onChange={(event) => {
                      updateFormData("state", event.target.value);
                    }}
                  >
                    {stateOptions}
                  </select>
                </div>
              </div>
              <div className="buttonContainer">
                <AnimatedButton
                  theme="light"
                  type="submit"
                  label="Submit Form"
                  className="franchiseInfoRequestForm"
                  processing={processing}
                />
              </div>
              {reCaptcha}
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}
