import React from 'react';

import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { useTabs } from '../helpers/hooks';

const ProductPanelContent = ({productData}) => {
  const variants = productData && "variants" in productData ? productData.variants : [productData];
  const {
    activeTab,
    setActiveTabIndex,
    isActiveTab
  } = useTabs(variants); 

  const handleTabButton = (index) => {
    // TODO: log event here
    setActiveTabIndex(index);
  }

  if (!activeTab) {
    return null;
  }

  const infoTabButtons = variants.map((variant, variantIndex) => {
    const isActive = isActiveTab(variantIndex );
    const buttonClassName = `tab${isActive ? ' isActive' : ''}`

    return (
      <button
        key={variant.id}
        id={variant.id}
        type="button"
        className={buttonClassName}
        onClick={() => handleTabButton(variantIndex)}
      >
        {variant.name}
      </button>
    )
  })

  const { images, content1, content2, bulletPoints1, bulletPoints2 } = activeTab;

  const par1 = content1 && (<p>{content1}</p>);
  const par2 = content2 && (<p>{content2}</p>);

  const ul1 = bulletPoints1 && (
    <ul>{bulletPoints1.map((text, index) => (<li key={index}>
      <span className="disc">
        &#9679;
      </span>
      {text}
    </li>))}</ul>
  );

  const ul2 = bulletPoints2 && (
    <ul>{bulletPoints2.map((text, index) => (<li key={index}>
      <span className="disc">
        &#9679;
      </span>
      {text}
    </li>))}</ul>
  );

  const slides = images && (images.map((imageData, index) => {
    const { fileName, altText } = imageData;

    return (
      <SwiperSlide key={index}>
        <img src={require(`../assets/images/${fileName}`)} className="productImage" alt={altText || ''} />
      </SwiperSlide>
    )
  }));

  return (
    <div className="productPanelContent">
      {variants.length > 1 && (
        <div className="tabList">
          {infoTabButtons}
        </div>
      )}
        
      { slides && slides.length > 0 &&
        <div className="carousel">
          <Swiper
            modules={[Autoplay, EffectFade, Pagination]}
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              bulletClass: 'pagination-bullet',
              bulletActiveClass: 'pagination-bullet-active'
            }}
            effect="fade"
            autoplay
          >
            {slides}
          </Swiper>
        </div>
      }
      <div className="description">
        {par1}
        {par2}
        {ul1}
        {ul2}
      </div>
    </div>
  );
};

export default ProductPanelContent;
