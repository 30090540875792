import React, { useState } from 'react';

const MedicalCard = (props) => {
  const { handleContinue } = props;

  const [noMedicalCard, setNoMedicalCard] = useState(false);

  return noMedicalCard ? (
    <>
      <h1 className="thinner1">
        <a href="https://health.ri.gov/healthcare/medicalmarijuana/for/patients/">Click here</a> for information on getting a RI medical card.
      </h1>
      <div className="screeningButtons">
        <button 
          className="screeningButtonNo"
          type="button"
          onClick={() => setNoMedicalCard(false)}
        >
          Back
        </button>
      </div>
    </>
  ) : (
    <>
      <h1 className="thinner2">
        Do you currently have a Rhode Island medical card?
      </h1>
      <div className="screeningButtons">
        <button 
          className="screeningButtonNo"
          type="button"
          onClick={() => setNoMedicalCard(true)}
        >
          No
        </button>
        <button 
          className="screeningButtonYes"
          type="button"
          onClick={() => handleContinue(true)}
        >
          Yes
        </button>
      </div>
    </>
  );
};

export default MedicalCard;