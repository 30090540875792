import React from 'react';

// Import header images
import LogoGreen from '../../../assets/images/logo-text-dark-green.png';
import LogoWhite from '../../../assets/images/logo-text-pale-green.png';
import CloseGreen from '../../../assets/images/close-green.png';
import CloseWhite from '../../../assets/images/close-white.png';

/*
  Incoming props:

  * theme ... "light" | "dark"
  * handleClose ... callback for close button
*/

const QuizHeader = ({theme, handleClose}) => {
  let logoImage;
  let closeImage;

  switch (theme) {
    case "light":
      logoImage = LogoGreen;
      closeImage = CloseGreen;
      break;
    case "dark":
      logoImage = LogoWhite;
      closeImage = CloseWhite;
      break;
    default:
      logoImage = "";
      closeImage = "";
  }

  return (
    <header className={theme}>
      <div className="contentContainer">
        <div className="content">
          <span className="buttonContainer left" />
          <span className="imageContainer">
            <span className="image">
              <img 
                src={logoImage} 
                alt="Logo"
              />
            </span>
          </span>
          <span className="buttonContainer right">
            <button 
              className="closeButton"
              onClick={handleClose}
            >
              <img 
                src={closeImage} 
                alt="Close"
              />
            </button>
          </span>
        </div>
      </div>
    </header>
  );
};

export default QuizHeader;