import React from 'react';

const RIFooter = () => {
  return (
    <section className="RIFooter">
      <div className="contentContainer">
        <div className="content">
          <p>
            560 S County Trail, Exeter, RI 02822, USA
          </p>
          <p>
            401 271 3869
          </p>
        </div>
      </div>
    </section>
  );
};

export default RIFooter;
