import React, { useRef, useState } from 'react';

// Import React Google reCAPTCHA
// https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha';

// Import components
import LogoSpinner from '../../../../shared/LogoSpinner';

// Import helpers and services
import { parsePhone } from '../../../../helpers/functions';
import { testEmail, testImageFile, testPhone } from '../../../../helpers/validation';
import { buildDeliveryFormFileParams, sendDeliveryForm } from '../../../../services/forms';
import { fileUpload } from '../../../../services/storage';
import { devMode } from '../../../../helpers/data';
import { getAnalytics , logEvent } from "firebase/analytics";

const SignUp = (props) => {
  const { formSubmissionSuccess } = props;
  const analytics = getAnalytics(); 
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: {
      number: '',
      formatted: ''
    },
    address: '',
    govId: null,
    medCard: null,
    deliveryCert: null,
    referralCode: ''
  });

  const [processing, setProcessing] = useState(false);

  // Create refs for file inputs
  const govIdInputRef = useRef(null);
  const medCardInputRef = useRef(null);
  const deliveryCertInputRef = useRef(null);

  // Create ref for reCAPTCHA widget
  const reCaptchaRef = useRef(null);

  // Build handlers
  const handleFormInput = (fieldName, value) => {
    const update = {};
    update[fieldName] = value;
    setForm((prevState) => ({
      ...prevState,
      ...update,
    }));
  };

  const handlePhoneInput = (value) => {
    const phone = parsePhone(value);
    handleFormInput('phone', phone);
  };

  const handleFileSelectButton = (fieldName) => {
    // Here we need to trigger the appropriate hidden input
    switch (fieldName) {
      case 'govId':
        const govIdInput = govIdInputRef.current;
        govIdInput?.click(); // eslint-disable-line
        break;
      case 'medCard':
        const medCardInput = medCardInputRef.current;
        medCardInput?.click(); // eslint-disable-line
        break;
      case 'deliveryCert':
        const deliveryCertInput = deliveryCertInputRef.current;
        deliveryCertInput?.click(); // eslint-disable-line
        break;
      default:
        console.log('Invalid field.');
    }
  };

  const handleFileSelect = (fieldName, fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      handleFormInput(fieldName, file);
    }
  };

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (processing) {
      console.log('Please wait...');
      return;
    }

    // Check input for validity
    const firstName = form.firstName.trim();
    const lastName = form.lastName.trim();
    const address = form.address.trim();
    if (!firstName || !lastName) {
      window.alert('Please enter a first and last name.');
      return;
    }
    if (!testEmail(form.email)) {
      window.alert('Please enter a valid email.');
      return;
    }
    if (!testPhone(form.phone.number)) {
      window.alert('Please enter a valid phone number.');
      return;
    }
    if (!address) {
      window.alert('Please enter the address listed on your government ID.');
      return;
    }
    if (!testImageFile(form.govId) || !testImageFile(form.medCard) || (form.deliveryCert && !testImageFile(form.deliveryCert))) {
      window.alert('Please select valid document images (.PNG, .JPG).');
      return;
    }

    // TODO: Limit size of attachments

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }

    setProcessing(true);

    try {
      // Upload documents to Cloud Storage
      const timestamp = new Date().toISOString();

      const govIdUploadParams = buildDeliveryFormFileParams({
        firstName,
        lastName,
        docType: 'govId',
        timestamp,
        file: form.govId
      });
      const govId = await fileUpload(govIdUploadParams);

      const medCardUploadParams = buildDeliveryFormFileParams({
        firstName,
        lastName,
        docType: 'medCard',
        timestamp,
        file: form.medCard
      });
      const medCard = await fileUpload(medCardUploadParams);

      let deliveryCert;
      if (form.deliveryCert) {
        const deliveryCertUploadParams = buildDeliveryFormFileParams({
          firstName,
          lastName,
          docType: 'deliveryCert',
          timestamp,
          file: form.deliveryCert
        });
        deliveryCert = await fileUpload(deliveryCertUploadParams);
      }

      // Build callbacks
      const success = () => {
        console.log('Form submission successful.');
        setProcessing(false);
        // Show completion screen
        formSubmissionSuccess();
      }
      
      const failure = () => {
        setProcessing(false);
        window.alert('An error occurred.  Please try again later.');
      }

      // Build object to send to cloud functions
      const formSubmissionParams = {
        token,
        firstName,
        lastName,
        email: form.email,
        phone: form.phone.number,
        address,
        govId,
        medCard,
        deliveryCert,
        referralCode: form.referralCode.trim(),
        success,
        failure,
      };

      // Send to service
      sendDeliveryForm(formSubmissionParams);
    } catch (error) {
      console.log('Failed to submit delivery form: ', error);
      setProcessing(false);
      window.alert('An error occurred.  Please try again later.');
    }

    logEvent(analytics, 'rhode_island_delivery_signup_complete');
  };

  // Build React reCaptcha element
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <section className="SignUp">
      <div className="contentContainer">
        <div className="content">
          <p className="sectionTitle">
            Sign up now for delivery
          </p>
          <form className="rhodeIslandDeliveryForm" onSubmit={handleSubmit}>
            <div className="formRow">
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={form.firstName}
                    onChange={(event) => {
                      handleFormInput('firstName', event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={form.lastName}
                    onChange={(event) => {
                      handleFormInput('lastName', event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={(event) => {
                      handleFormInput('email', event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={form.phone.formatted}
                    onChange={(event) => {
                      handlePhoneInput(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="text"
                    name="address"
                    placeholder="Address (must match gov ID)"
                    value={form.address}
                    onChange={(event) => {
                      handleFormInput('address', event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <div className="formInputContainer">
                    <input 
                      type="file"
                      ref={govIdInputRef}
                      name="govId"
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleFileSelect('govId', event.target.files);
                      }}
                    />
                    <span className="formInput">
                      {form.govId?.name || 'Government ID Photo'}
                    </span>
                    <span className="formInputButtonWrapper">
                      <button type="button" onClick={() => handleFileSelectButton('govId')}>
                        <img 
                          src={require('../../../../assets/images/upload.png')} 
                          alt="Upload"
                        />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <div className="formInputContainer">
                    <input 
                      type="file"
                      ref={medCardInputRef}
                      name="medCard"
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleFileSelect('medCard', event.target.files);
                      }}
                    />
                    <span className="formInput">
                      {form.medCard?.name || 'RI Medical Card Photo'}
                    </span>
                    <span className="formInputButtonWrapper">
                      <button type="button" onClick={() => handleFileSelectButton('medCard')}>
                        <img 
                          src={require('../../../../assets/images/upload.png')} 
                          alt="Upload"
                        />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="halfWidthFormItemWrapper">
                <div className="formItem">
                  <input 
                    type="text"
                    name="referralCode"
                    placeholder="Referral Code (optional)"
                    value={form.referralCode}
                    onChange={(event) => {
                      handleFormInput('referralCode', event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="formButtons">
              <button type="submit">
                { processing &&
                  <span className="logoSpinnerContainer">
                    <LogoSpinner />
                  </span>
                }
                Submit
              </button>
            </div>
            {reCaptcha}
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;