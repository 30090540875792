import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

import VideoBackground from '../../../assets/videos/landing-banner-video.mp4';
import VideoPoster from '../../../assets/images/landing-banner-video-image.jpg';

import HeaderBar from '../../../shared/HeaderBar';
import AnimatedButton from '../../../shared/AnimatedButton';

export default function LandingBanner() {
  const history = useHistory();
  const analytics = getAnalytics();

  const handleQuizButton = () => {
    history.push('/quiz');
    logEvent(analytics, "landing_page_quiz_started");
  };

  return (
    <>
      <video
        playsInline
        autoPlay
        muted
        loop
        poster={VideoPoster}
        className="BannerVideo"
      >
        <source src={VideoBackground} type="video/mp4" />
        <img src={VideoPoster} className="videoImage" alt="Products on shelves near a counter at a Sweetspot retail location" />
      </video>
      <div className="Banner">
        <HeaderBar 
          theme="light" 
          storeNavButton
        />
        <div className="contentWrapper">
          <div className="content">
            <h1>
              Worry-free
            </h1>
            <div className="quizButtonContainer">
              <AnimatedButton
                label="Take the Intro Quiz"
                handler={handleQuizButton}
                className="landingBanner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
