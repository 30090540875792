import React, { useEffect } from 'react';

import Footer from './Footer';
import HeaderBar from './HeaderBar';

export default function StoreMenu(props) {
  const { menuId } = props;
  const menu = `menu${menuId}`;

  useEffect(() => {
    // Inject IHeartJane script into the DOM
    // https://docs.iheartjane.com/partner-success/marketing-with-jane/embed-your-menu
    // We'll also remove the script when unmounting this component
    const id = menu.replace('menu', '');
    const storeMenu = document.getElementById(menu);
    const storeScript = document.createElement("script");
    storeScript.src = `https://api.iheartjane.com/v1/stores/${id}/embed.js`;
    storeScript.id = "jane-frame-script"; // Required per documentation
    storeScript.async = true;
    storeMenu.appendChild(storeScript);

    return (() => {
      storeMenu.removeChild(storeScript);
    });
  }, [menu]);

  return (
    <>
      <HeaderBar theme="light" hideNav />
      <div className="menuContainer">
        <main className="Store">
          <div className="Menu" id={menu} />
        </main>
      </div>
      <Footer hideNav />
    </>
  );
}
