import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function Age(props: any) {
  const analytics = getAnalytics();
  const {
    handleContinue
  } = props;

  const handleNoButton = () => {
    window.location.href = "https://www.google.com/";
    logEvent(analytics, "age_gate_no");
  }

  return (
    <>
      <h1>
        Find Your Sweetspot
      </h1>
      <p>
        Are you 21+
      </p>
      <div className="gateButtons">
        <button
          type="button"
          className="gateButtonNo"
          onClick={handleNoButton}
        >
          No I'm not
        </button>
        <button
          type="button"
          className="gateButtonYes"
          onClick={handleContinue}
        >
          Yes I am
        </button>
      </div>
    </>
  );
}
