import React from 'react';
import ReactDOM from 'react-dom';

// Import React Router
// https://reactrouter.com/
import { BrowserRouter } from 'react-router-dom';

// Import components
import App from './App';

// Import SCSS
import 'antd/dist/antd.min.css';
import './styles/main.scss';

// Initialize Firebase app
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase/firebase';
import { getAnalytics } from "firebase/analytics";

const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
