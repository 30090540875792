import React from 'react';

// Import React Router
// https://reactrouter.com/
import {
  useHistory,
} from "react-router-dom";

import HeaderBar from '../../../../shared/HeaderBar';

const Intro = (props) => {
  const { scrollRef } = props;

  // Acquire history
  const history = useHistory();

  const handleIntroButtonYes = () => {
    history.push('/store/ri001');
  };

  const handleIntroButtonNo = () => {
    const element = scrollRef.current;
    if (!element) {
      window.alert('Please complete and submit the form below.');
      return;
    }
    element.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <section className="Intro">
      <HeaderBar theme="light" hideNav />
      <div className="pageHeadingContainer">
        <div className="pageHeading">
          <h1>
            Medical Cannabis Delivered to all of Rhode Island!
          </h1>
        </div>
      </div>
      <div className="regCheckContainer">
        <div className="regCheck">
          <p className="sectionTitle">
            Have you submitted your registration info?
          </p>
          <div className="introButtons">
            <button type="button" className="introButtonYes" onClick={handleIntroButtonYes}>
              Yes
            </button>
            <button type="button" className="introButtonNo" onClick={handleIntroButtonNo}>
              No
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;