import React from 'react';

import {useHistory} from 'react-router-dom';

import LogoIconPaleGreen from '../../../assets/images/logo-icon-pale-green.png';
import AnimatedButton from '../../../shared/AnimatedButton';


export default function FranchiseFooter(props: any) {
  const history = useHistory();

  const handleInfoButton = () => {
    history.push('/franchising/info-request');
  };

  return (
    <footer className="FranchiseFooter">
      <div className="contentWrapper">
        <div className="content">
          <div className="blurb">
            <img src={LogoIconPaleGreen} alt="Sweetspot icon" />
            <p>
              Interested in becoming a partner? Let's chat!  3 ways to get in touch with us...
            </p>
          </div>
          <div className="itemWrapper">
            <div className="item">
              <p>
                Fill the form and we will get in touch.
              </p>
              <div className="infoButtonContainer">
                <AnimatedButton
                  theme="light"
                  label="Request Information"
                  handler={handleInfoButton}
                  className="franchiseFooter"
                />
              </div>
            </div>
            <div className="item">
              <p>
                Call and leave a message with our Franchise Director:
              </p>
              <span className="phone">
                <a href="tel:+12032000697">
                  203.200.0697
                </a>
              </span>
            </div>
            <div className="item">
              <p>
                Send us an email:
              </p>  
              <span className="email">
                <a href="mailto: franchise@sweetspotfarms.com">
                  franchise@sweetspotfarms.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="subText">
        <p>
          Sweetspot franchises are not currently being offered for locations in California, Florida, Hawaii, Illinois, Indiana, Kentucky, Michigan, Minnesota, Nebraska, New York, North Dakota, South Dakota, Texas, Utah, Virginia, Wisconsin, and Washington. 
        </p>
      </div>
    </footer> 
  );
}
