import React from 'react';

import OurStoryImage from '../../../assets/images/our-story-image.png';

export default function OurStory() {
  return (
    <section className="OurStory">
      <div className="imageContainerWrapper">
        <div className="imageContainer">
          <div className="caption">
            <p>
              We provide a worry-free cannabis experience.
            </p>
          </div>
          <div className="image">
            <img src={OurStoryImage} alt="A Sweetspot employee assists a shopper with merchandise." />
          </div>
        </div>
      </div>
      <div className="contentWrapper">
        <div className="content">
          <div className="itemsWrapper">
            <div className="item">
              <p>
                We give people control over their cannabis experience.
              </p>
            </div>
            <div className="item">
              <p>
                We positively impact the planet, people, and communities we serve.
              </p>
            </div>
            <div className="item">
              <p>
                We sell a product that helps people stress-less and enjoy-more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
