import React from 'react';

// Import React Router
// https://reactrouter.com/
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

// Import components
import FranchiseInfoRequest from './info-request/InfoRequest';
import FranchiseBanner from './sections/Banner';
import Blurb from '../../shared/Blurb';
import SectionHeading from '../../shared/SectionHeading';
import Industry from './sections/Industry';
import OurStory from './sections/OurStory';
import Investment from './sections/Investment';
import NextSteps from './sections/NextSteps';
import FranchiseFAQ from './sections/FAQ';
import FranchiseFooter from './sections/Footer';
import { Helmet } from 'react-helmet';

export default function Franchise() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/info-request`}>
        <FranchiseInfoRequest />
      </Route>
      <Route exact path={`${match.url}`}>
        <main className="Franchise">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sweetspot Weed Dispensary: Franchising</title>
          <link rel="canonical" href="https://sweetspotfarms.com/franchising"/>
        </Helmet>
          <FranchiseBanner />
          <Blurb
            larger
            text="We are seeking qualified franchisees interested in capitalizing on one of the fastest growing industries of this generation."
          />
          <SectionHeading theme="light" text="The Industry" scrollRef="industry" />
          <Industry />
          <SectionHeading theme="light" text="Our Story" scrollRef="story" />
          <OurStory />

          <SectionHeading theme="light" text="Investment & Fees" scrollRef="investment" />
          <Investment />
          <SectionHeading theme="light" text="The Next Steps" scrollRef="process" />
          <NextSteps />
          <SectionHeading theme="light" text="F.A.Q." scrollRef="faq" />
          <FranchiseFAQ />
          <FranchiseFooter />
        </main>
      </Route>
      <Route path="*">
        <Redirect to={`${match.url}`} />
      </Route>
    </Switch>
  );
}
