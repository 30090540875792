import React, { useRef, useState } from 'react';

// Import React Google reCAPTCHA
// https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha';

// Import helpers and services
import { devMode, stateValues } from '../helpers/data';
import { testEmail } from '../helpers/validation';
import { sendSubscribeForm } from '../services/forms';
import AnimatedButton from './AnimatedButton';

const SubscribeForm = () => {
  const [userData, setUserData] = useState({
    email: '',
    location: '',
  });
  const [processing, setProcessing] = useState(false);

  const reCaptchaRef = useRef(null);

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  }

  const updateUserData = (key, value) => {
    const update = {
      ...userData,
    };

    update[key] = value;
    setUserData(update);
  };

  const resetQuizData = () => {
    setUserData({
      email: '',
      location: '',
    });
  };

  const handleSubmit = async () => {
    if (processing) {
      console.log('Please wait...');
      return;
    }

    // Check to see if form fields have been filled out
    // If not, show alert; otherwise allow visitor to proceed
    if (!testEmail(userData.email.trim())) {
      window.alert("Please enter a valid email.");
    } else if (!userData.location) {
      window.alert("Please select a location.");
    } else {
      // Attempt to acquire reCAPTCHA token
      const token = await reCaptchaRef.current?.executeAsync();
      if (!token) {
        // Error handling handled by callbacks
        return;
      }

      // Build callbacks for sendSubscribeForm
      const success = () => {
        console.log("Form submission successful.");
        setProcessing(false);
        window.alert("We've received your information; thank you!");
        resetQuizData();
      }

      const failure = () => {
        setProcessing(false);
        window.alert("An error occurred when processing your request.  Please try again later.");
      }

      // Set processing to true
      setProcessing(true);
    
      // Fire middleware
      sendSubscribeForm({
        token,
        email: userData.email.trim(),
        location: userData.location,
        success, 
        failure
      });
    }
  };

  // Build location options
  let locationOptions = [];

  locationOptions.push(  
    <option key={-1} value="">
      {'State'}
    </option>
  );

  stateValues.forEach((state) => {
    locationOptions.push(
      <option 
        key={state.abbr}
        value={state.name}
      >
        {state.abbr}
      </option>
    );
  });

  // Build React reCaptcha element
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <>
      <div className="SubscribeForm">
        <div className="contentWrapper">
          <div className="content">
            <div className="introContainer">
              <span className="heading">
                Subscribe to Our Newsletter
              </span>
              <span className="text">
                Receive updates about deals, events, and store openings near you.
              </span>
            </div>
            <div className="formContent">
              <div className="emailInputContainer">
                <input 
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={userData.email}
                  onChange={(event) => {
                    updateUserData('email', event.target.value);
                  }}
                />
              </div>
              <div className="locationAndSubmissionButtonContainer">
                <div className="locationInputContainer">
                  <select 
                    name="location"
                    value={userData.location}
                    onChange={(event) => {
                      updateUserData("location", event.target.value);
                    }}
                  >
                    { locationOptions }
                  </select>
                </div>
                <div className="submissionButtonContainer">
                  <AnimatedButton
                    label="Sign up"
                    handler={handleSubmit}
                    processing={processing}
                    className="signup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reCaptcha}
    </>
  );
};

export default SubscribeForm;
