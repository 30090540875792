import React from 'react';
import Blurb from '../../shared/Blurb';
import { Helmet } from 'react-helmet';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
  } from "react-router-dom";

  export default function Loyalty() { 
    const match = useRouteMatch();

    return (
        <Switch>
            <main className="Loyalty"></main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sweetspot Weed Dispensary: Loyalty Sign Up</title>
          <link rel="canonical" href="https://sweetspotfarms.com/loyaltysignup"/>
        </Helmet>
        </Switch>
    )
  }