import React from 'react';

const SectionHeading = (props) => {
  const {theme, text, scrollRef} = (props);

  const headingClassName = `SectionHeading${theme === 'light' ? ' light' : ''}`;

  return (
    <div className={headingClassName} id={scrollRef}>
      <div className="contentContainer">
        <div className="content">
          <span className="circle">
            &#9679;
          </span>
          <span className="text">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
